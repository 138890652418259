import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/site-layout/site-layout';
import LandingPageImage from '../components/landing-page/lp-image';
import { Head } from '../components/site-layout/head';
import { getImage } from 'gatsby-plugin-image';

const IndexPage: React.FC<PageProps<GatsbyTypes.IndexPageQueryQuery>> = ({ data, location }) => {
  const image = data.lpImage && data.lpImage.childImageSharp ? getImage(data.lpImage.childImageSharp.gatsbyImageData) : undefined;
  return (
    <Layout location={location} footer={false}>
      <Head title="Home" imageSrc={image?.images.fallback?.src} />
      {image && <LandingPageImage image={image} alt={data.lpImage?.fields?.exif?.title} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    lpImage: file(relativePath: { eq: "images/lp-image.jpg" }) {
      fields {
        exif {
          title
        }
      }
      childImageSharp {
        gatsbyImageData(width: 4000)
      }
    }
  }
`;
