import React from 'react';
import styled from 'styled-components';
import { THEME } from '../../constants/theme';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

const Root = styled.div`
  height: 100vh;

  .gatsby-image-wrapper {
    background-color: ${THEME.light};
    height: 100vh;
  }
`;

interface LandingPageImageProps {
  image?: IGatsbyImageData;
  alt?: string;
}

const LandingPageImage: React.FC<LandingPageImageProps> = ({ image, alt}) => {
  return <Root>{image && alt !== undefined && <GatsbyImage image={image} alt={alt} />}</Root>;
};

export default LandingPageImage;
